import PackageJson from '../../package.json';


class ConfigurationManager {
	static appEnvironmentSignet = PackageJson.appEnvironmentSignet;
	static appVersion = PackageJson.version;
	static appRouterBaseName = PackageJson.appRouterBaseName;
	static appHtcontentsUrl = process.env.PUBLIC_URL + "/htcontents/";
	static appDossierUrl = process.env.PUBLIC_URL + "/dossier/";
	static get appApiBaseUrl() {
		var aReturnVal = process.env.REACT_APP_API_BASE_URL_STAGING
		if (ConfigurationManager.appEnvironmentSignet === AppEnvironment.production.signet()) {
			aReturnVal = process.env.REACT_APP_API_BASE_URL_PRODUCTION
		}
		return aReturnVal;
	}
	static dataTransferManagerEnvironmentType = "httpApi";
	static paypalBusinessClientId = function() {
		// TODO: Replace with process.env.PAYPAL_BUSINESS_CLIENT_ID
		return AppConstantsFetcher._g0();
	}
	static paypalCurrencyCode = "USD";
	// TODO: [p0] Update price.
	static premiumPlanYearlyAmountInUsd = 1.5;

	static appUpdatePopupYoutubeVideoEmbededUrl = "https://www.youtube.com/embed/gcBo9L6kHS8?rel=0&enablejsapi=1&controls=1&autoplay=0";
	static appUpdatePopupYoutubeVideoWebUrl = "https://www.youtube.com/watch?v=gcBo9L6kHS8";
}


class AppEnvironment {
	static production = new AppEnvironment("production");
	static staging = new AppEnvironment("staging");

	_signet = null;

	constructor(pSignet) {
		this._signet = pSignet;
	}

	signet() {
		return this._signet;
	}

}


/*
// http://www.freejsobfuscator.com/
class AppConstantsFetcher {
	static fetchPaypalBusinessClientId = function() {
		return "";
	}
}
*/
var _cs= {f62:()=> "f3",f39:()=> "f14",f44:()=> "f28",f55:()=> "f7",f35:()=> "f26",f22:()=> "M75",f3:()=> "vKn",f31:()=> "Ni",f11:()=> "hc_",f59:()=> "f10",f24:()=> "noJ",f50:()=> "f24",f57:()=> "f17",f5:()=> "aw",f20:()=> "X_",f4:()=> "0h",f54:()=> "f2",f64:()=> "f18",f32:()=> "irf",f25:()=> "2A",f43:()=> "f5",f9:()=> "rt8",f6:()=> "0H0",f56:()=> "f23",f41:()=> "f0",f60:()=> "f16",f12:()=> "uNB",f19:()=> "Aun",f30:()=> "_20",f1:()=> "TWs",f7:()=> "3x",f16:()=> "Qy",f63:()=> "f12",f49:()=> "f9",f65:()=> "f15",f26:()=> "lU4",f61:()=> "f4",f46:()=> "f27",f37:()=> "f13",f29:()=> "AU",f23:()=> "cy",f47:()=> "f31",f45:()=> "f8",f0:()=> "Hv",f33:()=> "f19",f10:()=> "hx4",f51:()=> "f22",f14:()=> "xGY",f53:()=> "f32",f2:()=> "qU",f42:()=> "f20",f48:()=> "f30",f18:()=> "Nk",f34:()=> "f29",f52:()=> "f21",f27:()=> "fh",f40:()=> "f11",f21:()=> "c-",f58:()=> "f6",f15:()=> "b",f36:()=> "f25",f28:()=> "6Yy",f13:()=> "Kr",f8:()=> "vq",f17:()=> "r0",f38:()=> "f1",f0l0:()=>"p" }; class AppConstantsFetcher { static _g0 = function() { return _cs[_cs.f34()]()+_cs[_cs.f41()]()+_cs[_cs.f37()]()+_cs[_cs.f63()]()+_cs[_cs.f51()]()+_cs[_cs.f50()]()+_cs[_cs.f35()]()+_cs[_cs.f42()]()+_cs[_cs.f64()]()+_cs[_cs.f61()]()+_cs[_cs.f43()]()+_cs[_cs.f38()]()+_cs[_cs.f57()]()+_cs[_cs.f40()]()+_cs[_cs.f33()]()+_cs[_cs.f62()]()+_cs[_cs.f59()]()+_cs[_cs.f36()]()+_cs[_cs.f60()]()+_cs[_cs.f48()]()+_cs[_cs.f54()]()+_cs[_cs.f45()]()+_cs[_cs.f53()]()+_cs[_cs.f39()]()+_cs[_cs.f44()]()+_cs[_cs.f46()]()+_cs[_cs.f49()]()+_cs[_cs.f56()]()+_cs[_cs.f52()]()+_cs[_cs.f55()]()+_cs[_cs.f47()]()+_cs[_cs.f58()]()+_cs[_cs.f65()](); } }


export { ConfigurationManager, AppEnvironment }
